<template>
  <section class="shop">
    <NavTop :productsInCart="productsInCart" :apiLink="apiLink" />
    <v-container fluid>
      <v-row>
        <v-col cols="1" md="2" class="pa-0 ma-0">
          <NavSidebar
            @changeCategory="changeCategory"
            :categories="categories"
          />
        </v-col>
        <v-col cols="11" md="10">
          <div>
            <v-container v-if="loading">
              <v-row>
                <v-col class="text-center">
                  <v-progress-circular
                    :size="200"
                    :width="15"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-else>
              <v-row>
                <v-col>
                  <v-container class="py-0">
                    <v-row class="py-0">
                      <v-col class="py-0">
                        <v-text-field
                          v-model="search"
                          clearable
                          :loading="loading"
                          label="Szukaj"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0" justify="end">
                      <v-col cols="5" sm="3" md="2" lg="2" xl="1" class="pt-0">
                        <v-btn
                          @click="changePageView('list')"
                          fab
                          dark
                          small
                          :outlined="pageView == 'list' ? false : true"
                          color="info"
                          class="mr-1"
                        >
                          <v-icon dark>
                            mdi-format-list-bulleted-square
                          </v-icon>
                        </v-btn>

                        <v-btn
                          @click="changePageView('grid')"
                          fab
                          dark
                          small
                          :outlined="pageView == 'grid' ? false : true"
                          color="info"
                        >
                          <v-icon dark> mdi-apps </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="py-0" sm="6" md="4" lg="3" xl="2">
                        <v-select
                          v-model="sortValue"
                          :items="sortTypes"
                          :item-text="sortTypes.text"
                          :item-value="sortTypes.shortName"
                          return-object
                          label="Sortowanie"
                          :loading="loading"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-container>
                    <v-row v-if="pageView == 'grid'">
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="2"
                        v-for="product in displayProducts"
                        :key="product.id"
                      >
                        <ShopProductGrid
                          :apiLink="apiLink"
                          :product="product"
                          :sales="sales"
                          @addToCart="addToCart"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        v-for="product in displayProducts"
                        :key="product.id"
                      >
                        <ShopProductList
                          :apiLink="apiLink"
                          :product="product"
                          :sales="sales"
                          @addToCart="addToCart"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="6">
                  <v-pagination
                    v-model="page"
                    class="my-4"
                    :length="pages"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="viewBuyInfo" :timeout="2000" color="success">
      Produkt został dodany
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewBuyInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import NavTop from "@/components/Navigations/NavTop.vue";
import NavSidebar from "@/components/Navigations/NavSidebar.vue";
import ShopProductGrid from "@/components/Shop/ShopProductGrid.vue";
import ShopProductList from "@/components/Shop/ShopProductList.vue";

export default {
  name: "Shop",
  components: {
    NavTop,
    NavSidebar,
    ShopProductGrid,
    ShopProductList,
  },
  data() {
    return {
      products: [],
      productsInCart: [],
      filteredProducts: [],
      categories: [],
      sortTypes: [
        {
          text: "Najwyższa cena",
          shortName: "highestPrice",
        },
        {
          text: "Najniższa cena",
          shortName: "lowestPrice",
        },
      ],
      sortValue: null,
      search: null,
      loading: false,
      pageView: "grid",
      viewBuyInfo: false,
      sales: [],
      page: 1,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    displayProducts() {
      return this.filteredProducts.slice((this.page - 1) * 24, this.page * 24);
    },
    pages() {
      return Math.ceil(this.filteredProducts.length / 24);
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.filteredProducts = this.products;
      }
      val && val !== this.select && this.querySelection(val);
    },
    sortValue() {
      this.sortProducts();
    },
  },
  created() {
    this.updateCart();
    this.getSales();
    this.getProducts();
    this.getCategories();
    if (localStorage.pageView) {
      this.pageView = localStorage.pageView;
    }
  },
  methods: {
    sortProducts() {
      if (this.sortValue.shortName == "highestPrice") {
        this.filteredProducts.sort(
          (a, b) => parseFloat(b.price) - parseFloat(a.price)
        );
      } else {
        this.filteredProducts.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
      }
      this.page = 1;
    },
    querySelection(v) {
      this.changeCategory("all");
      this.page = 1;
      this.filteredProducts = this.products.filter((e) => {
        return (
          (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1 ||
          (e.producer_name || "")
            .toLowerCase()
            .indexOf((v || "").toLowerCase()) > -1 ||
          (e.ean.toString() || "")
            .toLowerCase()
            .indexOf((v.toString() || "").toLowerCase()) > -1
        );
      });
    },
    addToCart(product, count) {
      this.viewBuyInfo = true;
      var productsInCart;
      if (localStorage.productsInCart) {
        productsInCart = JSON.parse(localStorage.productsInCart);
      } else {
        localStorage.productsInCart = "[]";
        productsInCart = JSON.parse(localStorage.productsInCart);
      }
      var is_in = 0;
      productsInCart.forEach((el) => {
        if (el.product.id == product.id) {
          el.count = parseFloat(el.count) + parseFloat(count);
          is_in = 1;
        }
      });
      if (is_in == 0) {
        productsInCart.push({
          product: product,
          count: parseFloat(count),
        });
      }
      localStorage.productsInCart = JSON.stringify(productsInCart);
      this.updateCart();
    },
    updateCart() {
      if (localStorage.productsInCart) {
        this.productsInCart = JSON.parse(localStorage.productsInCart);
      }
    },
    changePageView(view) {
      this.pageView = view;
      localStorage.pageView = view;
    },
    changeCategory(category) {
      this.page = 1;
      if (category == "all") {
        this.filteredProducts = this.products;
      } else if (category == "new") {
        this.filteredProducts = this.products.filter((el) => {
          return el.is_new == 1;
        });
      } else if (category == "sale") {
        console.log("hehe");
      } else {
        this.filteredProducts = this.products.filter((el) => {
          return el.producer_name == category;
        });
      }
    },
    async getProducts() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/getActiveProducts.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.products = this.filteredProducts = res.data;
        }
      });
      this.loading = false;
    },
    async getCategories() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/getProductors.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.categories = res.data;
          this.categories.sort((a, b) => {
            return a.name.localeCompare(b.name, "pl", {
              ignorePunctuation: true,
            });
          });
        }
      });
      this.loading = false;
    },
    async getSales() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/getActiveSales.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.sales = res.data;
        }
      });
    },
  },
};
</script>

<style>
</style>