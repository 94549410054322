<template>
  <v-container fluid class="ma-0 pa-0 shop-product-list">
    <v-row dense>
      <v-col cols="12" class="ma-0 pa-0">
        <v-card class="mx-auto pa-0 mb-0 mt-0" outlined>
          <v-list-item three-line>
            <v-avatar class="mt-0 mr-3 d-none d-sm-block" size="100" tile>
              <v-img
                class="align-end pointer"
                :src="imgLink"
                :lazy-src="imgLink"
                @click="goToProduct(product.ean)"
              ></v-img>
            </v-avatar>
            <v-container class="pa-2" fluid>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" sm="12" lg="5" class="">
                  <v-list-item-title
                    class="pointer"
                    @click="goToProduct(product.ean)"
                  >
                    <span class="text-h6 cut-text">{{ product.name }}</span>
                    <br /><span
                      ><small>{{ product.producer_name }}</small></span
                    >
                  </v-list-item-title>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="text-left text-sm-center">
                  <span class="d-sm-none text-md-h6">
                    Ilość {{ product.unit }} w opakowaniu:
                  </span>
                  <p class="d-none d-sm-block text-md-h6">
                    Ilość {{ product.unit }} w opakowaniu
                  </p>
                  <span class="text-md-h6">{{ product.in_package }}</span>
                </v-col>
                <v-col cols="12" sm="6" lg="3" class="text-left text-sm-center">
                  <span class="d-sm-none text-md-h6"
                    >Cena za {{ product.unit }}:
                  </span>
                  <p class="text-md-h6 d-none d-sm-block">
                    Cena za {{ product.unit }}
                  </p>
                  <span v-if="isProductOnSale"
                    ><span class="text-md-h6 old-price"
                      >{{ productPrice(product.price) }} zł</span
                    >
                    <span class="green--text text-md-h6">
                      {{
                        productPrice(
                          (this.product.price * (100 - this.isProductOnSale)) /
                            100
                        )
                      }}
                      zł</span
                    ></span
                  >
                  <span v-else class="text-md-h5"
                    >{{ productPrice(product.price) }} zł</span
                  >
                </v-col>

                <v-col cols="12" lg="1" class="text-left text-sm-center">
                  <v-container fluid class="pa-0 ma-0">
                    <v-row class="d-sm-none pa-0 ma-0">
                      <v-col cols="12" class="pl-0 ml-0 py-0 my-0">
                        <v-radio-group v-model="countType" row>
                          <v-radio label="opak" value="op"></v-radio>
                          <v-radio :label="product.unit" value="szt"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="d-sm-none pa-0 ma-0">
                      <v-col cols="6" class="pl-0 ml-0 py-0 my-0"
                        ><v-text-field
                          label="Ilość"
                          outlined
                          hide-details
                          v-model="productCount"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="6" class="pa-0 ma-0 text-right"
                        ><v-btn
                          color="green"
                          class="h-100 d-sm-none"
                          dark
                          fab
                          @click.prevent="addToCart(product)"
                        >
                          <v-icon>mdi-cart-plus</v-icon></v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-row class="d-none d-sm-flex my-lg-0">
                      <v-col cols="6" lg="12" class="ma-0 pa-0">
                        <v-radio-group class="ma-0" v-model="countType" row>
                          <v-radio label="opak" value="op"></v-radio>
                          <v-radio :label="product.unit" value="szt"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <v-col cols="6" lg="12" class="pa-0 mx-0 mb-1"
                        ><v-text-field
                          label="Ilość"
                          outlined
                          dense
                          hide-details
                          v-model="productCount"
                        ></v-text-field>
                        <v-btn
                          color="green"
                          class="h-100 d-none d-sm-block ma-auto"
                          dark
                          block
                          @click.prevent="addToCart(product)"
                        >
                          <v-icon>mdi-cart-plus</v-icon></v-btn
                        >
                      </v-col></v-row
                    >
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ShopProduct",
  data() {
    return {
      productCount: 1,
      countType: "op",
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    sales: {
      type: Array,
    },
  },
  computed: {
    imgLink() {
      if (this.product.img) {
        return `${this.apiLink}/api/uploads/product_img/${this.product.img}`;
      } else {
        return `${this.apiLink}/api/uploads/product_img/blank.png`;
      }
    },
    isProductOnSale() {
      let discounts = [];
      if (this.sales.length > 0) {
        this.sales.forEach((sale) => {
          if (sale.type == "all") {
            discounts.push(sale.discount_percent);
          } else if (sale.type == "producer_name") {
            if (sale.type_name == this.product.producer_name) {
              discounts.push(sale.discount_percent);
            }
          } else if (sale.type == "category_name") {
            if (sale.type_name == this.product.category_name) {
              discounts.push(sale.discount_percent);
            }
          }
        });
      } else {
        return false;
      }
      if (discounts.length > 0) {
        discounts.sort((a, b) => {
          return b - a;
        });
        return discounts[0];
      } else {
        return false;
      }
    },
  },
  methods: {
    productPrice(price) {
      let strPrice = price.toFixed(2).toString();
      if (strPrice.includes(".")) {
        let priceSplit = strPrice.split(".");
        return `${priceSplit[0]}.${priceSplit[1].padEnd(2, "0")}`;
      } else {
        return strPrice;
      }
    },
    addToCart(product) {
      let count = 0;
      if (this.countType == "op") {
        count = this.productCount * product.in_package;
      } else {
        count = this.productCount;
      }
      this.$emit("addToCart", product, count);
    },
    goToProduct(ean) {
      this.$router.push({ name: "SingleProduct", params: { ean: ean } });
    },
  },
};
</script>

<style>
.cut-text {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}
.shop-product-list .pointer {
  cursor: pointer;
}
.product-count-list {
  width: 50px;
}
.shop-product-list .old-price {
  text-decoration: line-through;
  font-size: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>