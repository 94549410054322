<template>
  <section id="login" class="login">
    <v-container>
      <v-row justify="center" class="main-row">
        <v-col align-self="center" md="4">
          <v-card elevation="2">
            <v-row justify="center" class="pt-8">
              <v-col class="mx-5 text-center">
                <v-img
                  contain
                  max-height="500"
                  max-width="500"
                  src="@/assets/logo.png"
                  class="ma-auto"
                ></v-img>
              </v-col>
            </v-row>

            <v-card-text class="px-8 mt-5">
              <v-form>
                <v-text-field
                  v-model="email"
                  ref="email"
                  label="Email"
                  :loading="loading"
                  :rules="[rules.required, rules.email]"
                  @keydown.enter="validateLogin"
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  ref="password"
                  label="Hasło"
                  :loading="loading"
                  :rules="[rules.required]"
                  :type="showpassword ? 'text' : 'password'"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpassword = !showpassword"
                  @keydown.enter="validateLogin"
                ></v-text-field>

                <p class="text-center">
                  Logując się, wyrażasz zgodę na
                  <a
                    target="_blank"
                    href="https://e-gecos.pl/polityka-prywatnosci/"
                    >politykę prywatności</a
                  >
                </p>

                <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  class="mt-3"
                  @click="validateLogin"
                  block
                  large
                >
                  Zaloguj
                </v-btn>

                <v-btn
                  :to="{ name: 'ForgotPassword' }"
                  text
                  block
                  x-small
                  color="info"
                  class="mt-4"
                  >Zapomniałeś hasła?</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewInfo" :timeout="2000" color="red">
      {{ resMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
      loading: false,
      email: "",
      password: "",
      checkbox: false,
      viewInfo: false,
      resMessage: "",
      showpassword: false,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  created() {
    if (localStorage.token) {
      this.checkToken();
    }
  },
  methods: {
    validateLogin() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.login();
      }
    },
    async login() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/auth/login.php`,
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data != 0) {
              localStorage.token = res.data;
              this.$router.push({
                name: "Shop",
              });
            } else {
              this.viewInfo = true;
              this.resMessage = "Niepoprawne dane logowania";
            }
          } else {
            this.viewInfo = true;
            this.resMessage = "Podano niepełne dane logowania";
          }
        })
        .catch(() => {
          this.viewInfo = true;
          this.resMessage = "Błąd podczas logowania";
        });
      this.loading = false;
    },
    async checkToken() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/auth/check_cu_token.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data != 0) {
              if (res.data == "client") {
                localStorage.userType = "client";
                this.$router.push({
                  name: "Shop",
                });
              } else if (res.data == "user") {
                localStorage.userType = "user";
                this.$router.push({
                  name: "Shop",
                });
              } else {
                localStorage.removeItem("token");
                localStorage.removeItem("userType");
              }
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("userType");
            }
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userType");
        });
      this.loading = false;
    },
  },
};
</script>

<style>
.login .main-row {
  height: 100vh;
}
</style>