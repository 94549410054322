<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    :close-on-content-click="false"
    :offset-y="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-3" color="green" dark v-bind="attrs" v-on="on">
        <v-badge color="primary" :content="cartCount" :value="cartCount">
          <span class="d-none d-sm-inline">Koszyk</span>
          <v-icon class="ml-0 ml-sm-2"> mdi-cart-outline </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-container fluid class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-col class="ma-0 pa-0">Produkt</v-col>
            <v-col class="text-right ma-0 pa-0">Ilość szt</v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-list max-height="400" class="mini-cart">
        <div
          v-for="productLine in products"
          :key="productLine.product.id"
          class="mt-2"
        >
          <v-list-item>
            <v-list-item-avatar>
              <img
                :src="
                  productLine.product.img
                    ? `${apiLink}/api/uploads/product_img/${productLine.product.img}`
                    : `${apiLink}/api/uploads/product_img/blank.png`
                "
                :lazy-src="
                  productLine.product.img
                    ? `${apiLink}/api/uploads/product_img/${productLine.product.img}`
                    : `${apiLink}/api/uploads/product_img/blank.png`
                "
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{
                productLine.product.name
              }}</v-list-item-title>

              <v-list-item-subtitle
                >{{
                  productPrice(
                    isProductOnSale(productLine.product)
                      ? (
                          (productLine.product.price *
                            (100 - isProductOnSale(productLine.product))) /
                          100
                        ).toFixed(2)
                      : productLine.product.price
                  )
                }}
                zł za {{ productLine.product.unit }} (w opak.
                {{ productLine.product.in_package }}
                {{ productLine.product.unit }})</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action class="text-h5">
              {{ productLine.count }}
            </v-list-item-action>
            <v-btn
              absolute
              color="red"
              class="white--text"
              fab
              right
              top
              x-small
              @click="deleteProduct(productLine)"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>
        </div>
      </v-list>

      <v-card-actions>
        <v-container fluid class="pa-0 ma-0">
          <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <v-list class="pa-0 ma-0">
                <v-list-item class="py-0 ma-0">
                  <v-list-item-content class="pa-0 ma-0">
                    <v-list-item-title class="pa-0 ma-0"
                      >Suma</v-list-item-title
                    >

                    <v-list-item-subtitle class="pa-0 ma-0"
                      >{{ totalPrice }} zł</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-action class="text-h5">
                    {{ cartCount }}
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="green white--text"
                :disabled="products.length > 0 ? false : true"
                block
                link
                :to="{ name: 'Cart' }"
              >
                Przejdz do zamówienia
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "MiniCart",
  data() {
    return {
      products: [],
      sales: [],
    };
  },
  props: {
    productsInCart: {
      type: Array,
    },
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    totalPrice() {
      var totalPrice = 0;
      this.products.forEach((el) => {
        totalPrice += this.isProductOnSale(el.product)
          ? (
              (parseFloat(el.product.price) *
                (100 - this.isProductOnSale(el.product))) /
              100
            ).toFixed(2)
          : parseFloat(el.product.price) * el.count;
      });
      return this.productPrice(totalPrice);
    },
    cartCount() {
      if (this.products) {
        var productsCount = 0;
        this.products.forEach((el) => {
          productsCount += el.count;
        });
        return productsCount;
      } else {
        return 0;
      }
    },
  },
  watch: {
    productsInCart(newVal) {
      this.products = newVal;
    },
  },
  created() {
    this.getSales();
    this.products = this.productsInCart;
  },
  methods: {
    productPrice(price) {
      let strPrice = price.toFixed(2).toString();
      if (strPrice.includes(".")) {
        let priceSplit = strPrice.split(".");
        return `${priceSplit[0]}.${priceSplit[1].padEnd(2, "0")}`;
      } else {
        return strPrice;
      }
    },
    isProductOnSale(product) {
      let discounts = [];
      if (this.sales.length > 0) {
        this.sales.forEach((sale) => {
          if (sale.type == "all") {
            discounts.push(sale.discount_percent);
          } else if (sale.type == "producer_name") {
            if (sale.type_name == product.producer_name) {
              discounts.push(sale.discount_percent);
            }
          } else if (sale.type == "category_name") {
            if (sale.type_name == product.category_name) {
              discounts.push(sale.discount_percent);
            }
          }
        });
      } else {
        return false;
      }
      if (discounts.length > 0) {
        discounts.sort((a, b) => {
          return b - a;
        });
        return discounts[0];
      } else {
        return false;
      }
    },
    deleteProduct(productLine) {
      var productsInCart = this.products.filter((el) => {
        return el != productLine;
      });
      localStorage.productsInCart = JSON.stringify(productsInCart);
      this.products = JSON.parse(localStorage.productsInCart);
      this.updateCart();
    },
    updateCart() {
      if (localStorage.productsInCart) {
        this.products = JSON.parse(localStorage.productsInCart);
      }
    },
    async getSales() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/getActiveSales.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.sales = res.data;
        }
      });
    },
  },
};
</script>

<style>
.mini-cart {
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}
</style>