<template>
  <section class="history">
    <NavTop :productsInCart="productsInCart" :apiLink="apiLink" />
    <v-container fluid>
      <v-row>
        <v-col md="12"
          ><HistoryTable
            :apiLink="apiLink"
            :orders="orders"
            :loading="loading"
            @sendOrder="sendOrder"
            @addProductsToCart="addProductsToCart"
            @cancelOrder="cancelOrder"
        /></v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewInfo" :timeout="2000" :color="colorInfo">
      {{ textInfo }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import NavTop from "@/components/Navigations/NavTop.vue";
import HistoryTable from "@/components/History/HistoryTable.vue";

export default {
  name: "History",
  data() {
    return {
      productsInCart: [],
      orders: [],
      viewInfo: false,
      textInfo: "",
      colorInfo: "success",
      loading: false,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  components: {
    NavTop,
    HistoryTable,
  },
  created() {
    this.updateCart();
    this.getOrders();
    if (this.$route.params.message) {
      this.viewInfo = true;
      this.textInfo = this.$route.params.message;
      this.colorInfo = "success";
    }
  },
  methods: {
    updateCart() {
      if (localStorage.productsInCart) {
        this.productsInCart = localStorage.productsInCart;
        this.productsInCart = JSON.parse(localStorage.productsInCart);
      }
    },
    addToCart(products) {
      var productsInCart;
      if (localStorage.productsInCart) {
        productsInCart = JSON.parse(localStorage.productsInCart);
      } else {
        localStorage.productsInCart = "[]";
        productsInCart = JSON.parse(localStorage.productsInCart);
      }
      products.forEach((product) => {
        var is_in = 0;
        productsInCart.forEach((el) => {
          if (el.product.id == product.product.id) {
            el.count += product.count;
            is_in = 1;
          }
        });
        if (is_in == 0) {
          productsInCart.push(product);
        }
      });
      console.log(productsInCart);
      localStorage.productsInCart = JSON.stringify(productsInCart);
      this.updateCart();
    },
    async getOrders() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/getOrders.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.orders = res.data;
            this.orders.sort(
              (a, b) => b.order_no.split("/")[1] - a.order_no.split("/")[1]
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async sendOrder(order) {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/createOrderAgain.php`,
        data: {
          token: localStorage.token,
          order: order,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              console.log(res.data);
              this.viewInfo = true;
              this.textInfo = "Ponowne zamówienie zostało złożone";
              this.colorInfo = "success";
            } else {
              this.viewInfo = true;
              this.textInfo = res.data;
              this.colorInfo = "warning";
            }
          }
        })
        .catch((err) => {
          this.viewInfo = true;
          this.textInfo = "Błąd wysyłania zamówienia: " + err;
          this.colorInfo = "red";
        });
      this.getOrders();
      this.loading = false;
    },
    async addProductsToCart(order) {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/addProductsToCart.php`,
        data: {
          token: localStorage.token,
          order: order,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.addToCart(res.data);
            this.viewInfo = true;
            this.textInfo = "Dodano dostępne produkty do koszyka";
            this.colorInfo = "success";
          }
        })
        .catch((err) => {
          this.viewInfo = true;
          this.textInfo = "Błąd wysyłania zamówienia: " + err;
          this.colorInfo = "red";
        });
      this.getOrders();
      this.loading = false;
    },
    async cancelOrder(order) {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/changeOrderStatus.php`,
        data: {
          token: localStorage.token,
          order: order,
          status: "canceled",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.viewInfo = true;
              this.textInfo = "Zamówienie anulowane";
              this.colorInfo = "success";
            } else {
              this.viewInfo = true;
              this.textInfo = "Błąd";
              this.colorInfo = "red";
            }
          }
        })
        .catch((err) => {
          this.viewInfo = true;
          this.textInfo = "Błąd wysyłania zamówienia: " + err;
          this.colorInfo = "red";
        });
      this.getOrders();
      this.loading = false;
    },
  },
};
</script>

<style>
</style>