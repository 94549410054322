var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cart"},[_c('NavTop',{attrs:{"productsInCart":_vm.productsInCart,"apiLink":_vm.apiLink}}),_c('v-container',{staticClass:"mt-3",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.productsInCart),function(productLine){return _c('v-col',{key:productLine.product.id,staticClass:"mb-4",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-avatar',{staticClass:"ma-3 d-none d-sm-block",attrs:{"size":"100","tile":""}},[_c('v-img',{staticClass:"align-end",attrs:{"src":productLine.product.img
                    ? (_vm.apiLink + "/api/uploads/product_img/" + (productLine.product.img))
                    : (_vm.apiLink + "/api/uploads/product_img/blank.png"),"lazy-src":productLine.product.img
                    ? (_vm.apiLink + "/api/uploads/product_img/" + (productLine.product.img))
                    : (_vm.apiLink + "/api/uploads/product_img/blank.png")}})],1),_c('v-list-item-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('span',{staticClass:"text-h5 cut-text"},[_vm._v(_vm._s(productLine.product.name)+" "),_c('small',[_vm._v("(EAN: "+_vm._s(productLine.product.ean)+")")])]),_c('br'),_c('span',[_c('small',[_vm._v(_vm._s(productLine.product.producer_name))])])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(productLine.product.description))])],1),_c('v-col',{staticClass:"text-md-center",attrs:{"cols":"6","sm":"6","md":"2"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('span',{staticClass:"text-sm-h5"},[_vm._v("Ilość "+_vm._s(productLine.product.unit))])]),_c('v-list-item-subtitle',[_c('v-text-field',{staticClass:"product-count mx-md-auto",attrs:{"value":productLine.count,"prepend-icon":"mdi-minus","append-outer-icon":"mdi-plus","type":"number"},on:{"click:prepend":function($event){return _vm.minusCount(productLine)},"click:append-outer":function($event){productLine.count++},"input":function($event){productLine.count = $event}}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","offset-md":"1"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('span',{staticClass:"text-sm-h5"},[_vm._v("Waga")])]),_c('v-list-item-subtitle',[_c('v-text-field',{staticClass:"product-weight",attrs:{"value":(((
                          productLine.count * productLine.product.weight
                        ).toFixed(2)) + " kg"),"type":"text","readonly":""}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('span',{class:_vm.isProductOnSale(productLine.product)
                            ? 'text-sm-h5 green--text'
                            : 'text-sm-h5'},[_vm._v("Cena za "+_vm._s(productLine.product.unit))])]),_c('v-list-item-subtitle',[_c('v-text-field',{staticClass:"product-price",attrs:{"value":_vm.productPrice(
                            _vm.isProductOnSale(productLine.product)
                              ? (productLine.product.price *
                                  (100 -
                                    _vm.isProductOnSale(productLine.product))) /
                                  100
                              : productLine.product.price
                          ) + ' zł',"readonly":"","type":"text"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","offset-md":"3"}},[_c('v-list-item-title',{staticClass:"mb-1"},[_c('span',{staticClass:"text-sm-h5"},[_vm._v("Suma")])]),_c('v-list-item-subtitle',[_c('v-text-field',{staticClass:"product-summary",attrs:{"value":(((
                          productLine.count *
                          (_vm.isProductOnSale(productLine.product)
                            ? (
                                (productLine.product.price *
                                  (100 -
                                    _vm.isProductOnSale(productLine.product))) /
                                100
                              ).toFixed(2)
                            : productLine.product.price)
                        ).toFixed(2)) + " zł"),"type":"text","readonly":""}})],1)],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"white--text",attrs:{"absolute":"","color":"red","fab":"","left":"","top":"","small":""},on:{"click":function($event){return _vm.deleteProduct(productLine)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Komentarz do zamówienia"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('p',{staticClass:"my-auto text-h5 text-center text-md-left"},[_vm._v(" Podsumowanie ")])]),_c('v-col',{attrs:{"offset-sm":"2","offset-md":"4","offset-xl":"6","cols":"6","sm":"3","md":"2","xl":"1"}},[_c('span',[_vm._v("Całkowita waga")]),_c('v-text-field',{staticClass:"product-summary",attrs:{"type":"text","readonly":""},model:{value:(_vm.productSumWeight),callback:function ($$v) {_vm.productSumWeight=$$v},expression:"productSumWeight"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3","md":"2","xl":"1"}},[_c('span',[_vm._v("Kwota do zapłaty")]),_c('v-text-field',{staticClass:"product-summary",attrs:{"type":"text","readonly":""},model:{value:(_vm.productSumPrice),callback:function ($$v) {_vm.productSumPrice=$$v},expression:"productSumPrice"}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"success","disabled":_vm.productsInCart.length > 0 ? false : true},on:{"click":_vm.sendOrder}},[_vm._v("Złóż zamówienie")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000,"color":"red"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","loading":_vm.loading},on:{"click":function($event){_vm.viewDeleteInfo = false}}},'v-btn',attrs,false),[_vm._v(" Zamknij ")])]}}]),model:{value:(_vm.viewDeleteInfo),callback:function ($$v) {_vm.viewDeleteInfo=$$v},expression:"viewDeleteInfo"}},[_vm._v(" Usunięto produkt ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }