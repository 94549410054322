<template>
  <v-app>
    <v-main>
      <router-view :apiLink="apiLink" />
    </v-main>

    <v-footer app>
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
  data: () => ({
    apiLink: "https://zamowienia.e-gecos.pl",
    // apiLink: "http://192.168.0.163:80/zamawiarka",
  }),
  created() {
    if (localStorage.themeDarkMode) {
      this.$vuetify.theme.dark = JSON.stringify(localStorage.themeDarkMode);
    }
  },
};
</script>

<style></style>
