<template>
  <div class="sidebar">
    <v-navigation-drawer permanent :mini-variant="navShow">
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item link @click="navShow = !navShow" class="d-md-none">
            <v-list-item-icon>
              <v-icon>mdi-menu</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ukryj</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="changeCategory('new')">
            <v-list-item-icon>
              <v-icon>mdi-alert-decagram</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nowe</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item link @click="changeCategory('sale')">
            <v-list-item-icon>
              <v-icon>mdi-sale</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Promocje</v-list-item-title>
          </v-list-item> -->

          <v-divider></v-divider>

          <v-list-item link @click="changeCategory('all')">
            <v-list-item-icon>
              <v-icon>mdi-grain</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Wszystkie</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-for="category in categories"
            :key="category.name"
            link
            @click="changeCategory(category.name)"
          >
            <v-list-item-icon>
              <v-icon>mdi-arrow-right-thin</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="category-title"
              >{{ category.name }} ({{ category.count }})</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavSidebar",
  data() {
    return {
      selectedItem: 2,
      navShow: false,
    };
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    changeCategory(category) {
      this.$emit("changeCategory", category);
    },
  },
};
</script>

<style>
.avatar-content {
  padding: 0 !important;
}
.sidebar {
  z-index: 1;
  position: fixed;
  height: 90vh;
}
.sidebar .category-title {
  white-space: normal;
}
.sidebar .v-navigation-drawer__content {
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
</style>