<template>
  <v-container class="py-2" fluid>
    <v-row class="text-center d-block">
      <v-col class="py-1 footer-col">
        Strona stworzona przez
        <a href="https://jawasoft.pl" target="_blank">Jawasoft</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer-col {
  font-size: 11px;
}
</style>