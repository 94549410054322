<template>
  <v-container fluid class="mt-3 pa-0 history-card">
    <v-row dense>
      <v-col
        v-for="productLine in order.products"
        :key="productLine.product.id"
        cols="12"
        class="mb-4"
      >
        <v-card class="mx-auto" outlined>
          <v-list-item three-line class="d-none d-lg-block">
            <v-list-item-content>
              <v-container fluid class="py-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-list-item-title class="mb-1">
                      <span class="text-h6 cut-text"
                        >{{ productLine.product.name }}
                        <small
                          >(EAN: {{ productLine.product.ean }})</small
                        ></span
                      >
                      <br /><span
                        ><small>{{
                          productLine.product.producer_name
                        }}</small></span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      productLine.product.description
                    }}</v-list-item-subtitle>
                  </v-col>

                  <v-col cols="12" sm="6" md="2" class="text-md-center py-0">
                    <v-list-item-title class="mb-1">
                      <span class="text-md-h5"
                        >Ilość {{ productLine.product.unit }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><v-text-field
                        :value="productLine.count"
                        class="product-count mx-md-auto"
                        type="number"
                        readonly
                      ></v-text-field
                    ></v-list-item-subtitle>
                  </v-col>

                  <v-col cols="12" sm="6" md="1" class="py-0">
                    <v-list-item-title class="mb-1">
                      <span class="text-md-h5">Waga</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><v-text-field
                        :value="`${(
                          productLine.count * productLine.product.weight
                        ).toFixed(2)} kg`"
                        class="product-weight"
                        type="text"
                        readonly
                      ></v-text-field
                    ></v-list-item-subtitle>
                  </v-col>
                  <v-col cols="12" sm="6" md="1" class="py-0">
                    <v-list-item-title class="mb-1">
                      <span class="text-md-h5"
                        >Cena za {{ productLine.product.unit }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><v-text-field
                        :value="`${productLine.product.price} zł`"
                        readonly
                        class="product-price"
                        type="text"
                      ></v-text-field
                    ></v-list-item-subtitle>
                  </v-col>
                  <v-col md="1" offset-md="1" class="py-0">
                    <v-list-item-title class="mb-1">
                      <span class="text-md-h5">Suma</span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      ><v-text-field
                        :value="`${productPrice(
                          productLine.product.price * productLine.count
                        )} zł`"
                        class="product-summary"
                        type="text"
                        readonly
                      ></v-text-field
                    ></v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item-content>
          </v-list-item>

          <v-container class="d-lg-none">
            <v-row>
              <v-col cols="12" md="6">
                <span class=""
                  >{{ productLine.product.name }}
                  <small>(EAN: {{ productLine.product.ean }})</small></span
                >
                <br /><span
                  ><small>{{ productLine.product.producer_name }}</small></span
                >{{ productLine.product.description }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3" class="py-0">
                <span class="text-md-h6"
                  >Ilość {{ productLine.product.unit }}:
                  {{ productLine.count }}</span
                >
              </v-col>

              <v-col cols="12" sm="6" md="2" class="py-0">
                <span class="text-md-h6"
                  >Waga
                  {{
                    `${productLine.count * productLine.product.weight} kg`
                  }}</span
                >
              </v-col>
              <v-col cols="12" sm="6" md="3" class="py-0">
                <span class="text-md-h6"
                  >Cena za {{ productLine.product.unit }}:
                  {{ `${productLine.product.price} zł` }}</span
                >
              </v-col>
              <v-col md="3" offset-md="1" class="py-0">
                <span class="text-md-h6"
                  ><strong
                    >Suma:
                    {{
                      productPrice(
                        productLine.product.price * productLine.count
                      ) + " zł"
                    }}</strong
                  ></span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HistoryCart",
  data() {
    return {};
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    productPrice(price) {
      let strPrice = price.toFixed(2).toString();
      if (strPrice.includes(".")) {
        let priceSplit = strPrice.split(".");
        return `${priceSplit[0]}.${priceSplit[1].padEnd(2, "0")}`;
      } else {
        return strPrice;
      }
    },
  },
};
</script>

<style>
.history-table .product-count {
  width: 60px;
}
.history-card .product-summary {
  width: 80px;
}
.history-card .product-weight {
  width: 70px;
}
.history-card .product-price {
  width: 90px;
}
.cut-text {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>