import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";

import Login from '../views/Login.vue'
import Shop from '../views/Shop.vue'
import SingleProduct from '../views/SingleProduct.vue'
import Cart from '../views/Cart.vue'
import History from '../views/History.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/sklep',
    name: 'Shop',
    component: Shop,
    meta: {
      requiresClientOrUserAuth: true,
    },
  },
  {
    path: '/produkt/:ean',
    name: 'SingleProduct',
    component: SingleProduct,
    meta: {
      requiresClientOrUserAuth: true,
    },
  },
  {
    path: '/zamowienie',
    name: 'Cart',
    component: Cart,
    meta: {
      requiresClientOrUserAuth: true,
    },
  },
  {
    path: '/historia-zamowien',
    name: 'History',
    component: History,
    meta: {
      requiresClientOrUserAuth: true,
    },
  },
  {
    path: '/nowe-haslo/:resetLink',
    name: 'NewPassword',
    component: () => import('../views/NewPassword.vue'),
  },
  {
    path: '/przypomnienie-hasla',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/cms-dashboard',
    name: 'CMSDashboard',
    component: () => import('../views/CMS/CMSDashboard.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-zamowienia',
    name: 'CMSOrders',
    component: () => import('../views/CMS/CMSOrders.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-klienci',
    name: 'CMSCustomers',
    component: () => import('../views/CMS/CMSCustomers.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-pracownicy',
    name: 'CMSUsers',
    component: () => import('../views/CMS/CMSUsers.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-produkty',
    name: 'CMSProducts',
    component: () => import('../views/CMS/CMSProducts.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-logi',
    name: 'CMSLogs',
    component: () => import('../views/CMS/CMSLogs.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
  {
    path: '/cms-promocje',
    name: 'CMSSale',
    component: () => import('../views/CMS/CMSSale.vue'),
    meta: {
      requiresUserAuth: true,
    },
  },
]

const router = new VueRouter({
  routes
})

const apiUrl = "https://zamowienia.e-gecos.pl";
// const apiUrl = "http://192.168.0.163:80/zamawiarka";

router.beforeEach((to, from, next) => {
  // check to see if route requires auth
  if (to.matched.some((rec) => rec.meta.requiresClientOrUserAuth)) {
    if (localStorage.token) {
      axios({
        method: "post",
        url: `${apiUrl}/api/views/auth/check_cu_token.php`,
        withCredentials: true,
        data: {
          token: localStorage.token
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if(res.data == 'client') {
              localStorage.userType = 'client';
              next();
            } else if(res.data == 'user') {
              localStorage.userType = 'user';
              next();
            } else {
              if (from.name != "Login") {
                localStorage.removeItem("token");
                localStorage.removeItem("userType");
                next({ name: "Login" });
              }
            }
          } else {
            if (from.name != "Login") {
              localStorage.removeItem("token");
              localStorage.removeItem("userType");
              next({ name: "Login" });
            }
          }
        })
        .catch(() => {
          if (from.name != "Login") {
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            next({ name: "Login" });
          }
        });
    } else {
      if (from.name != "Login") {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
        next({ name: "Login" });
      }
    }
  } else if(to.matched.some((rec) => rec.meta.requiresUserAuth)) {
    if (localStorage.token) {
      axios({
        method: "post",
        url: `${apiUrl}/api/views/auth/check_u_token.php`,
        withCredentials: true,
        data: {
          token: localStorage.token
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if(res.data == 'user') {
              localStorage.userType = 'user';
              next();
            } else {
              if (from.name != "Login") {
                localStorage.removeItem("token");
                localStorage.removeItem("userType");
                next({ name: "Login" });
              }
            }
          } else {
            if (from.name != "Login") {
              localStorage.removeItem("token");
              localStorage.removeItem("userType");
              next({ name: "Login" });
            }
          }
        })
        .catch(() => {
          if (from.name != "Login") {
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            next({ name: "Login" });
          }
        });
    } else {
      if (from.name != "Login") {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
        next({ name: "Login" });
      }
    }
  } else {
    next();
  }
});

export default router
