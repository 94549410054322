<template>
  <div class="history-table">
    <v-expansion-panels>
      <v-expansion-panel v-for="order in orders" :key="order.id">
        <v-expansion-panel-header disable-icon-rotate>
          <div>
            <span class="text-h5 mr-5">
              Zamówienie nr {{ order.order_no }}
            </span>
            <span
              ><v-icon>mdi-calendar-month-outline</v-icon>
              {{ order.datetime_created.split(" ")[0] }}</span
            >
            <span class="mx-5"
              ><v-icon>mdi-clock-outline</v-icon>
              {{ order.datetime_created.split(" ")[1].split(":")[0] }}:{{
                order.datetime_created.split(" ")[1].split(":")[1]
              }}</span
            >
            <v-spacer class="d-block d-md-none"></v-spacer>
            <span
              ><v-icon>mdi-cash-multiple</v-icon> {{ sumTotal(order) }} zł</span
            >
          </div>
          <template v-slot:actions>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :color="getColor(order)" v-bind="attrs" v-on="on">
                  {{ getIcon(order) }}
                </v-icon>
              </template>
              <span>{{ order.status_name }}</span>
            </v-tooltip>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <HistoryCart :order="order" />
          <v-container fluid class="py-0">
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Komentarz do zamówienia"
                  v-model="order.comments"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="mx-auto" outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="mb-1">
                        <span class="text-h5">Podsumowanie</span>
                      </v-list-item-title>
                    </v-list-item-content>
                    <div>
                      <v-text-field
                        :value="`${sumTotal(order)} zł`"
                        class="product-summary"
                        type="text"
                        readonly
                      ></v-text-field>
                    </div>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="5">
                <v-btn
                  dark
                  color="green"
                  block
                  @click.prevent="sendOrder(order)"
                  :loading="loading"
                  >Zamów ponownie <v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-col>
              <v-col md="5">
                <v-btn
                  dark
                  color="green"
                  block
                  @click.prevent="addProductsToCart(order)"
                  :loading="loading"
                  >Dodaj produkty do koszyka
                  <v-icon>mdi-cart-arrow-down</v-icon></v-btn
                >
              </v-col>
              <v-col md="2">
                <v-btn
                  color="red"
                  class="white--text"
                  block
                  @click.prevent="cancelOrder(order)"
                  :loading="loading"
                  :disabled="order.status == 'new' ? false : true"
                  >Anuluj zamówienie <v-icon>mdi-close-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import HistoryCart from "@/components/History/HistoryCart.vue";

export default {
  data() {
    return {};
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    HistoryCart,
  },
  methods: {
    sumTotal(order) {
      var sumTotal = 0;
      order.products.forEach((p) => {
        sumTotal += p.product.price * p.count;
      });
      return sumTotal.toFixed(2);
    },
    getIcon(order) {
      if (order.status == "new") {
        return "mdi-send-circle-outline";
      } else if (order.status == "in_progress") {
        return "mdi-clock-outline";
      } else if (order.status == "canceled") {
        return "mdi-close-outline";
      } else {
        return "mdi-check";
      }
    },
    getColor(order) {
      if (order.status == "new") {
        return "teal";
      } else if (order.status == "in_progress") {
        return "warning";
      } else if (order.status == "canceled") {
        return "red";
      } else {
        return "info";
      }
    },
    sendOrder(order) {
      this.$emit("sendOrder", order);
    },
    addProductsToCart(order) {
      this.$emit("addProductsToCart", order);
    },
    cancelOrder(order) {
      this.$emit("cancelOrder", order);
    },
  },
};
</script>

<style>
.history-table .product-summary {
  width: 150px;
}
</style>