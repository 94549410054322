var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","close-on-content-click":false,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"green","dark":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"primary","content":_vm.cartCount,"value":_vm.cartCount}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Koszyk")]),_c('v-icon',{staticClass:"ml-0 ml-sm-2"},[_vm._v(" mdi-cart-outline ")])],1)],1)]}}])},[_c('v-card',[_c('v-card-title',[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_vm._v("Produkt")]),_c('v-col',{staticClass:"text-right ma-0 pa-0"},[_vm._v("Ilość szt")])],1)],1)],1),_c('v-list',{staticClass:"mini-cart",attrs:{"max-height":"400"}},_vm._l((_vm.products),function(productLine){return _c('div',{key:productLine.product.id,staticClass:"mt-2"},[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":productLine.product.img
                  ? (_vm.apiLink + "/api/uploads/product_img/" + (productLine.product.img))
                  : (_vm.apiLink + "/api/uploads/product_img/blank.png"),"lazy-src":productLine.product.img
                  ? (_vm.apiLink + "/api/uploads/product_img/" + (productLine.product.img))
                  : (_vm.apiLink + "/api/uploads/product_img/blank.png")}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(productLine.product.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.productPrice( _vm.isProductOnSale(productLine.product) ? ( (productLine.product.price * (100 - _vm.isProductOnSale(productLine.product))) / 100 ).toFixed(2) : productLine.product.price ))+" zł za "+_vm._s(productLine.product.unit)+" (w opak. "+_vm._s(productLine.product.in_package)+" "+_vm._s(productLine.product.unit)+")")])],1),_c('v-list-item-action',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(productLine.count)+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"absolute":"","color":"red","fab":"","right":"","top":"","x-small":""},on:{"click":function($event){return _vm.deleteProduct(productLine)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider')],1)}),0),_c('v-card-actions',[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 ma-0"},[_c('v-list-item',{staticClass:"py-0 ma-0"},[_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('v-list-item-title',{staticClass:"pa-0 ma-0"},[_vm._v("Suma")]),_c('v-list-item-subtitle',{staticClass:"pa-0 ma-0"},[_vm._v(_vm._s(_vm.totalPrice)+" zł")])],1),_c('v-list-item-action',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.cartCount)+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"green white--text","disabled":_vm.products.length > 0 ? false : true,"block":"","link":"","to":{ name: 'Cart' }}},[_vm._v(" Przejdz do zamówienia ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }