<template>
  <v-app-bar app color="grey lighten-1">
    <div class="d-flex align-center">
      <router-link link :to="{ name: 'Shop' }">
        <v-img
          alt="Gecos Logo"
          class="shrink ml-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="120"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <MiniCart :productsInCart="productsInCart" :apiLink="apiLink" />

    <v-menu
      :offset-y="true"
      bottom
      origin="center center"
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <span class="d-none d-sm-inline">Więcej</span>
          <v-icon class="ml-0 ml-sm-2">mdi-cog-outline</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-if="userType == 'user'"
          link
          dark
          :to="{ name: 'CMSDashboard' }"
        >
          <v-list-item-title>
            <v-btn color="green" block>
              CMS
              <v-icon class="ml-2"> mdi-view-dashboard-outline </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-list-item link dark :to="{ name: 'Shop' }">
          <v-list-item-title>
            <v-btn color="green" block link>
              Sklep
              <v-icon class="ml-2"> mdi-home </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-list-item v-if="userType == 'client'" link :to="{ name: 'History' }">
          <v-list-item-title>
            <v-btn color="info" block>
              Historia
              <v-icon class="ml-2"> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-divider class="my-3"></v-divider>
        <v-list-item>
          <v-list-item-title>
            <v-btn color="secondary" block @click="logout">
              Wyloguj
              <v-icon class="ml-2"> mdi-logout-variant </v-icon>
            </v-btn></v-list-item-title
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-switch
              @click="changeDarkTheme"
              v-model="darkTheme"
              label="Ciemny motyw"
            ></v-switch>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import MiniCart from "@/components/Navigations/MiniCart.vue";

export default {
  name: "NavTop",
  components: {
    MiniCart,
  },
  props: {
    productsInCart: {
      type: Array,
    },
    apiLink: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      darkTheme: false,
    };
  },
  computed: {
    userType() {
      if (localStorage.userType) {
        return localStorage.userType;
      } else {
        return false;
      }
    },
  },
  created() {
    if (localStorage.themeDarkMode) {
      this.darkTheme = JSON.parse(localStorage.themeDarkMode);
    }
    this.$vuetify.theme.dark = this.darkTheme;
  },
  methods: {
    changeDarkTheme() {
      localStorage.themeDarkMode = JSON.stringify(this.darkTheme);
      this.$vuetify.theme.dark = this.darkTheme;
    },
    async logout() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/auth/logout.php`,
        data: {
          token: localStorage.token,
        },
      });

      localStorage.removeItem("token");
      localStorage.removeItem("userType");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  padding-left: 8px;
  padding-right: 8px;
}
.sidebar-name {
  font-size: 18px;
  margin-bottom: 0;
}
.sidebar-role {
  font-size: 13px;
}
.avatar-img {
  object-fit: cover;
}
</style>