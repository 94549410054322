<template>
  <section class="cart">
    <NavTop :productsInCart="productsInCart" :apiLink="apiLink" />
    <v-container fluid class="mt-3">
      <v-row dense>
        <v-col
          v-for="productLine in productsInCart"
          :key="productLine.product.id"
          cols="12"
          class="mb-4"
        >
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-avatar class="ma-3 d-none d-sm-block" size="100" tile>
                <v-img
                  class="align-end"
                  :src="
                    productLine.product.img
                      ? `${apiLink}/api/uploads/product_img/${productLine.product.img}`
                      : `${apiLink}/api/uploads/product_img/blank.png`
                  "
                  :lazy-src="
                    productLine.product.img
                      ? `${apiLink}/api/uploads/product_img/${productLine.product.img}`
                      : `${apiLink}/api/uploads/product_img/blank.png`
                  "
                ></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-list-item-title class="mb-1">
                        <span class="text-h5 cut-text"
                          >{{ productLine.product.name }}
                          <small
                            >(EAN: {{ productLine.product.ean }})</small
                          ></span
                        >
                        <br /><span
                          ><small>{{
                            productLine.product.producer_name
                          }}</small></span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        productLine.product.description
                      }}</v-list-item-subtitle>
                    </v-col>
                    <v-col cols="6" sm="6" md="2" class="text-md-center">
                      <v-list-item-title class="mb-1">
                        <span class="text-sm-h5"
                          >Ilość {{ productLine.product.unit }}</span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        ><v-text-field
                          :value="productLine.count"
                          prepend-icon="mdi-minus"
                          append-outer-icon="mdi-plus"
                          @click:prepend="minusCount(productLine)"
                          @click:append-outer="productLine.count++"
                          @input="productLine.count = $event"
                          class="product-count mx-md-auto"
                          type="number"
                        ></v-text-field
                      ></v-list-item-subtitle>
                    </v-col>
                    <v-col cols="6" sm="6" md="2" offset-md="1">
                      <v-list-item-title class="mb-1">
                        <span class="text-sm-h5">Waga</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        ><v-text-field
                          :value="`${(
                            productLine.count * productLine.product.weight
                          ).toFixed(2)} kg`"
                          class="product-weight"
                          type="text"
                          readonly
                        ></v-text-field
                      ></v-list-item-subtitle>
                    </v-col>
                    <v-col cols="6" sm="6" md="2">
                      <v-list-item-title class="mb-1">
                        <span
                          :class="
                            isProductOnSale(productLine.product)
                              ? 'text-sm-h5 green--text'
                              : 'text-sm-h5'
                          "
                          >Cena za {{ productLine.product.unit }}</span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        ><v-text-field
                          :value="
                            productPrice(
                              isProductOnSale(productLine.product)
                                ? (productLine.product.price *
                                    (100 -
                                      isProductOnSale(productLine.product))) /
                                    100
                                : productLine.product.price
                            ) + ' zł'
                          "
                          readonly
                          class="product-price"
                          type="text"
                        ></v-text-field
                      ></v-list-item-subtitle>
                    </v-col>
                    <v-col cols="6" sm="6" md="2" offset-md="3">
                      <v-list-item-title class="mb-1">
                        <span class="text-sm-h5">Suma</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        ><v-text-field
                          :value="`${(
                            productLine.count *
                            (isProductOnSale(productLine.product)
                              ? (
                                  (productLine.product.price *
                                    (100 -
                                      isProductOnSale(productLine.product))) /
                                  100
                                ).toFixed(2)
                              : productLine.product.price)
                          ).toFixed(2)} zł`"
                          class="product-summary"
                          type="text"
                          readonly
                        ></v-text-field
                      ></v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
            </v-list-item>
            <v-btn
              absolute
              color="red"
              class="white--text"
              fab
              left
              top
              small
              @click="deleteProduct(productLine)"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            label="Komentarz do zamówienia"
            v-model="comments"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" outlined>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="4" md="4" lg="4" class="my-auto"
                  ><p class="my-auto text-h5 text-center text-md-left">
                    Podsumowanie
                  </p></v-col
                >
                <v-col
                  offset-sm="2"
                  offset-md="4"
                  offset-xl="6"
                  cols="6"
                  sm="3"
                  md="2"
                  xl="1"
                >
                  <span>Całkowita waga</span>
                  <v-text-field
                    v-model="productSumWeight"
                    class="product-summary"
                    type="text"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" xl="1">
                  <span>Kwota do zapłaty</span>
                  <v-text-field
                    v-model="productSumPrice"
                    class="product-summary"
                    type="text"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            block
            large
            color="success"
            :disabled="productsInCart.length > 0 ? false : true"
            @click="sendOrder"
            >Złóż zamówienie</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewDeleteInfo" :timeout="2000" color="red">
      Usunięto produkt
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="viewDeleteInfo = false"
          :loading="loading"
        >
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import NavTop from "@/components/Navigations/NavTop.vue";

export default {
  name: "Cart",
  components: {
    NavTop,
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      productsInCart: [],
      viewDeleteInfo: false,
      loading: false,
      comments: "",
      sales: [],
    };
  },
  computed: {
    productSumPrice() {
      var sumPrice = 0;
      this.productsInCart.forEach((el) => {
        sumPrice +=
          (this.isProductOnSale(el.product)
            ? (
                (el.product.price * (100 - this.isProductOnSale(el.product))) /
                100
              ).toFixed(2)
            : el.product.price) * el.count;
      });
      return `${sumPrice.toFixed(2)} zł`;
    },
    productSumWeight() {
      var sumWeight = 0;
      this.productsInCart.forEach((el) => {
        sumWeight += el.product.weight * el.count;
      });
      return `${sumWeight.toFixed(2)} kg`;
    },
  },
  created() {
    this.getSales();
    if (localStorage.productsInCart) {
      this.updateCart();
    } else {
      this.$router.push({ name: "Shop" });
    }
  },
  methods: {
    productPrice(price) {
      let strPrice = price.toFixed(2).toString();
      if (strPrice.includes(".")) {
        let priceSplit = strPrice.split(".");
        return `${priceSplit[0]}.${priceSplit[1].padEnd(2, "0")}`;
      } else {
        return strPrice;
      }
    },
    isProductOnSale(product) {
      let discounts = [];
      if (this.sales.length > 0) {
        this.sales.forEach((sale) => {
          if (sale.type == "all") {
            discounts.push(sale.discount_percent);
          } else if (sale.type == "producer_name") {
            if (sale.type_name == product.producer_name) {
              discounts.push(sale.discount_percent);
            }
          } else if (sale.type == "category_name") {
            if (sale.type_name == product.category_name) {
              discounts.push(sale.discount_percent);
            }
          }
        });
      } else {
        return false;
      }
      if (discounts.length > 0) {
        discounts.sort((a, b) => {
          return b - a;
        });
        return discounts[0];
      } else {
        return false;
      }
    },
    minusCount(productLine) {
      if (productLine.count > 0) {
        productLine.count -= 1;
      }
    },
    deleteProduct(productLine) {
      this.viewDeleteInfo = true;
      var productsInCart = this.productsInCart.filter((el) => {
        return el != productLine;
      });
      localStorage.productsInCart = JSON.stringify(productsInCart);
      this.productsInCart = JSON.parse(localStorage.productsInCart);
      this.updateCart();
    },
    updateCart() {
      if (localStorage.productsInCart) {
        this.productsInCart = JSON.parse(localStorage.productsInCart);
      }
    },
    clearStorage() {
      localStorage.removeItem("productsInCart");
    },
    async sendOrder() {
      this.loading = true;
      let productsToSend = this.productsInCart;
      productsToSend.forEach((el) => {
        let prodSale = this.isProductOnSale(el.product);
        if (prodSale) {
          el.product.price = (
            (el.product.price * (100 - prodSale)) /
            100
          ).toFixed(2);
        }
      });
      console.log(productsToSend);
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/orders/createOrder.php`,
        data: {
          token: localStorage.token,
          products: productsToSend,
          comments: this.comments,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.clearStorage();
            this.$router.push({
              name: "History",
              params: { message: "Złożono nowe zamówienie" },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    async getSales() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/getActiveSales.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.sales = res.data;
        }
      });
    },
  },
};
</script>

<style>
.cart .product-count {
  width: 130px;
}
.cart .product-in_package {
  width: 70px;
}
.cart .product-summary {
  width: 120px;
}
.cart .product-weight {
  width: 70px;
}
.cart .product-price {
  width: 120px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.cut-text {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}
</style>