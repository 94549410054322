<template>
  <section class="single-product">
    <NavTop :productsInCart="productsInCart" :apiLink="apiLink" />
    <v-container>
      <v-row class="mt-1" justify="center">
        <v-col cols="12" sm="6" md="6" xl="4">
          <v-img
            contain
            class="align-end"
            max-height="350px"
            :src="imgLink"
            :lazy-src="imgLink"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6" md="6" xl="4">
          <v-card class="mx-auto">
            <v-card-text>
              <p class="text-h6 text-md-h5 text--primary">
                {{ product.name }}
              </p>
              <div class="text--primary">
                {{ product.description }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row justify="space-between">
                  <v-col class="text-h6 text-md-h4 mb-0">
                    <span v-if="isProductOnSale">
                      <span class="old-price">{{ product.price }} zł</span>
                      <span class="text-h6 text-md-h4 green--text">
                        {{
                          (
                            (product.price * (100 - isProductOnSale)) /
                            100
                          ).toFixed(2)
                        }}
                        zł</span
                      >
                    </span>
                    <span v-else>{{ product.price }} zł</span>
                  </v-col>
                  <v-col cols="3" class="mb-0">
                    <v-btn
                      dark
                      color="green"
                      block
                      @click.prevent="addToCart(product)"
                    >
                      <v-icon>mdi-cart-plus</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" xl="8">
          <v-card class="mx-auto">
            <v-card-text>
              <v-container>
                <v-row class="pb-0">
                  <v-col cols="12" class="pb-0 ml-2">
                    <p
                      class="
                        text-h4
                        text-md-h4
                        text-center
                        text-md-left
                        text--primary
                      "
                    >
                      Szczegóły
                    </p>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="py-0"
                ><v-row
                  ><v-col md="6"
                    ><v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Nazwa</th>
                            <th class="text-left">Wartość</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in details1" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table></v-col
                  ><v-col md="6"
                    ><v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Nazwa</th>
                            <th class="text-left">Wartość</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in details2" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table></v-col
                  ></v-row
                ></v-container
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewBuyInfo" :timeout="2000" color="success">
      Produkt został dodany
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewBuyInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import NavTop from "@/components/Navigations/NavTop.vue";

export default {
  name: "SingleProduct",
  data() {
    return {
      productLoading: false,
      product: {},
      viewBuyInfo: false,
      productsInCart: [],
      sales: [],
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    imgLink() {
      if (this.product.img) {
        return `${this.apiLink}/api/uploads/product_img/${this.product.img}`;
      } else {
        return `${this.apiLink}/api/uploads/product_img/blank.png`;
      }
    },
    details1() {
      return [
        {
          name: "Producent",
          calories: this.product.producer_name,
        },
        {
          name: "Kategoria",
          calories: this.product.category_name,
        },
        {
          name: "W opakowaniu",
          calories: `${this.product.in_package} ${this.product.unit}`,
        },
      ];
    },
    details2() {
      return [
        {
          name: "Ean",
          calories: this.product.ean,
        },
        {
          name: "Waga",
          calories: this.product.weight,
        },
      ];
    },
    isProductOnSale() {
      let discounts = [];
      if (this.sales.length > 0) {
        this.sales.forEach((sale) => {
          if (sale.type == "all") {
            discounts.push(sale.discount_percent);
          } else if (sale.type == "producer_name") {
            if (sale.type_name == this.product.producer_name) {
              discounts.push(sale.discount_percent);
            }
          } else if (sale.type == "category_name") {
            if (sale.type_name == this.product.category_name) {
              discounts.push(sale.discount_percent);
            }
          }
        });
      } else {
        return false;
      }
      if (discounts.length > 0) {
        discounts.sort((a, b) => {
          return b - a;
        });
        return discounts[0];
      } else {
        return false;
      }
    },
  },
  components: {
    NavTop,
  },
  created() {
    if (this.$route.params.ean) {
      this.getSales();
      this.getProduct();
    } else {
      this.$router.push({ name: "Shop" });
    }
    this.updateCart();
  },
  methods: {
    addToCart(product) {
      this.viewBuyInfo = true;
      var productsInCart;
      if (localStorage.productsInCart) {
        productsInCart = JSON.parse(localStorage.productsInCart);
      } else {
        localStorage.productsInCart = "[]";
        productsInCart = JSON.parse(localStorage.productsInCart);
      }
      var is_in = 0;
      productsInCart.forEach((el) => {
        if (el.product.id == product.id) {
          el.count++;
          is_in = 1;
        }
      });
      if (is_in == 0) {
        productsInCart.push({
          product: product,
          count: 1,
        });
      }
      localStorage.productsInCart = JSON.stringify(productsInCart);
      this.updateCart();
    },
    updateCart() {
      if (localStorage.productsInCart) {
        this.productsInCart = localStorage.productsInCart;
        this.productsInCart = JSON.parse(localStorage.productsInCart);
      }
    },
    async getProduct() {
      this.productLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/products/getProduct.php`,
        data: {
          token: localStorage.token,
          product_ean: this.$route.params.ean,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.product = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.productLoading = false;
    },
    async getSales() {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/getActiveSales.php`,
        data: {
          token: localStorage.token,
        },
      }).then((res) => {
        if (res.status === 200) {
          this.sales = res.data;
        }
      });
    },
  },
};
</script>

<style>
.single-product .old-price {
  text-decoration: line-through;
  font-size: 16px;
}
</style>