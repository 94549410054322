<template>
  <v-card class="shop-product">
    <v-img
      class="white--text align-end pointer"
      height="200px"
      :src="imgLink"
      :lazy-src="imgLink"
      @click="goToProduct(product.ean)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-card-title v-if="isProductOnSale" class="green py-0">
        <small>{{ `- ${isProductOnSale}%` }}</small></v-card-title
      >
    </v-img>
    <v-card-subtitle
      class="pb-0 product-name pointer"
      @click="goToProduct(product.ean)"
    >
      {{ product.name }} ({{ product.in_package }} {{ product.unit }})
    </v-card-subtitle>

    <v-card-actions>
      <v-container>
        <v-row justify="space-between">
          <v-col cols="8" class="text-h6">
            <span v-if="isProductOnSale">
              <span class="old-price"
                >{{ productPrice(product.price) }} zł</span
              >
              <span class="green--text">
                {{
                  productPrice((product.price * (100 - isProductOnSale)) / 100)
                }}
                zł</span
              >
            </span>
            <span v-else>{{ productPrice(product.price) }} zł</span></v-col
          >
          <v-col cols="4" class="my-auto"> za {{ product.unit }}</v-col>
        </v-row>
        <v-col cols="12" class="pl-0 ml-0 py-0 my-0">
          <v-radio-group v-model="countType" row>
            <v-radio label="opak" value="op"></v-radio>
            <v-radio :label="product.unit" value="szt"></v-radio>
          </v-radio-group>
        </v-col>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Ilość "
              outlined
              hide-details
              v-model="productCount"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="my-auto">
            <v-btn color="green" dark block @click.prevent="addToCart(product)">
              <v-icon>mdi-cart-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ShopProduct",
  data() {
    return {
      productCount: 1,
      countType: "op",
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    sales: {
      type: Array,
    },
  },
  computed: {
    imgLink() {
      if (this.product.img) {
        return `${this.apiLink}/api/uploads/product_img/${this.product.img}`;
      } else {
        return `${this.apiLink}/api/uploads/product_img/blank.png`;
      }
    },
    isProductOnSale() {
      let discounts = [];
      if (this.sales.length > 0) {
        this.sales.forEach((sale) => {
          if (sale.type == "all") {
            discounts.push(sale.discount_percent);
          } else if (sale.type == "producer_name") {
            if (sale.type_name == this.product.producer_name) {
              discounts.push(sale.discount_percent);
            }
          } else if (sale.type == "category_name") {
            if (sale.type_name == this.product.category_name) {
              discounts.push(sale.discount_percent);
            }
          }
        });
      } else {
        return false;
      }
      if (discounts.length > 0) {
        discounts.sort((a, b) => {
          return b - a;
        });
        return discounts[0];
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    productPrice(price) {
      let strPrice = price.toFixed(2).toString();
      if (strPrice.includes(".")) {
        let priceSplit = strPrice.split(".");
        return `${priceSplit[0]}.${priceSplit[1].padEnd(2, "0")}`;
      } else {
        return strPrice;
      }
    },
    addToCart(product) {
      let count = 0;
      if (this.countType == "op") {
        count = this.productCount * product.in_package;
      } else {
        count = this.productCount;
      }
      this.$emit("addToCart", product, count);
    },
    goToProduct(ean) {
      this.$router.push({ name: "SingleProduct", params: { ean: ean } });
    },
  },
};
</script>

<style>
.shop-product .product-name {
  font-size: 16px;
}
.shop-product .old-price {
  text-decoration: line-through;
  font-size: 12px;
}
.shop-product .pointer {
  cursor: pointer;
}
</style>